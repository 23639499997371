import cn from 'classnames';
import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react';

import Radio from './Radio';

interface RadioGroupProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  wrapperClassName?: string;
  register?: any;
  elements?: (Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & { label: string })[];
  error?: boolean;
  tooltip?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

function RadioGroup(
  { name, wrapperClassName, register, elements, className, error, required, onChange, ...props }: RadioGroupProps,
  ref
) {
  const content = (
    <div className={cn('flex flex-col gap-1', wrapperClassName)} ref={ref}>
      {elements && elements.map((element, index) => (
        <Radio
          {...props}
          {...element}
          {...(register ? register(name) : [])}
          key={index}
          label={element.label}
          name={name}
          className={className}
          error={error}
          required={required}
          onChange={onChange}
        />
      ))}
    </div>
  );
  return content;
}

export default forwardRef<HTMLInputElement, RadioGroupProps>(RadioGroup)
